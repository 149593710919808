import { Grid } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import ContactForm from '../ContactForm';
import InternalLink from '../InternalLink';
import Layout from '../Layout';
import OriginalSubheading from '../Subheading';
import PageTitle from '../PageTitle';
import SmallText from '../SmallText';
import Textblob from '../Textblob';

const Subheading = styled(OriginalSubheading)`
  font-family: ffvCorporateFat, sans-serif;
  font-size: 1.4rem;
`;

const Section = styled.section`
  margin-top: 3rem;
`;

export default ({ pageContext: { searchIndex } }) => (
  <Layout background={true} searchIndex={searchIndex}>
    <PageTitle>General Terms and Conditions</PageTitle>
    <Grid container spacing={24} justify="center">
      <Grid item xs={12} sm={6} md={4}>
        <Section>
          <SmallText>
            <p>
              <a href="/gtc.pdf">
                <InternalLink small>General Terms and Conditions (PDF)</InternalLink>
              </a>
            </p>
            <p>
              <a href="/agb.pdf">
                <InternalLink small>Allgemeine Geschäftsbedingungen (PDF)</InternalLink>
              </a>
            </p>
          </SmallText>
        </Section>
      </Grid>
    </Grid>
  </Layout>
);
